//orderstatus.js
import Vue from 'vue'
import request from '@/utils/request'
import qs from 'qs'
import axios from 'axios'

// 订单查询
export function searchOrder (id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state) {
  let data = {
    id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/fwsorderall',
    method: 'post',
    data
  })
}
// 订单查询--姚阔
export function fwsorderalls (id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state,staff_id,isAettlement) {
  let data = {
    id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state,staff_id,isAettlement
  }
  data = qs.stringify(data)
  return request({
    url: '/other/fwsorderalls',
    method: 'post',
    data
  })
}

//结算
export function jiesuan (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/other/jiesuan',
    method: 'post',
    data
  })
}

//异常
export function yichang (id,refundReason) {
  let data = {
    id,refundReason
  }
  data = qs.stringify(data)
  return request({
    url: '/other/yichang',
    method: 'post',
    data
  })
}


export function jsamount (id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state,staff_id,isAettlement) {
  let data = {
    id,page,limit,starttime,endtime,editor,title,attribute,realname,qikan_name,state,staff_id,isAettlement
  }
  data = qs.stringify(data)
  return request({
    url: '/other/jsamount',
    method: 'post',
    data
  })
}

// 查看订单状态详情
export function orderStatusDetail (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/showorderstate',
    method: 'post',
    data
  })
}
// 更换处理人
export function changeStaff (fws_orders_id,staff_id) {
  let data = {
    fws_orders_id,staff_id
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/modifyorderstaff',
    method: 'post',
    data
  })
}


// 下载附件
export function downloadFile(urlPath) {

  if (!urlPath) {
    Vue.prototype.$message.error("暂无附件文件");
    return;
  }
  axios({
    method: 'post',
    url: '/staff/DownLoad?urlPath='+urlPath,
    responseType: "blob",
    data:{
      urlPath:urlPath
    }
  }).then(response => {
    console.log("response====:", response);
    let url = window.URL.createObjectURL(new Blob([response.data]))
    let link = document.createElement('a')
    const fname = urlPath.lastIndexOf('/')
    const str = urlPath.substr(fname+1)//截取文件名称字符串
    console.log(str);
    link.href = url;
    link.setAttribute('download', str);
    document.body.appendChild(link);
    link.click();
  });

}